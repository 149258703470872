@import "./src/common/general.scss";
.l-footer__columns {
  display: flex;
  flex-wrap: wrap;
}

.l-footer__heading {
  width: 100%;
  text-align: left;
  font-variation-settings: 'wght' $fontWeightSemiBold;

  &:after {
    display: block;
    right: 0.25em;
  }
}

.l-footer_block-list {
  margin-top: ($lineHeight * 0.5rem);
}

.l-footer__column {
  border-top: 1px solid $colorGrey--200;
  padding: ($lineHeight * 0.5rem) 1em;
  flex: 1 0 100%;
  order: 2;

  @media #{$gtLine} {
    order: unset;
    flex: 1 1 0px;
    border: none;
    padding: 0;
  }
}

.l-footer__link {
  &:hover,
  &:focus {
    color: var(--colorBrandPrimary--400);
    text-decoration: underline;
  }
}

.l-footer__link__icon {
  height: 0.75em;
  margin-left: 0.25rem;
}
