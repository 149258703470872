@import "./src/common/general.scss";
.l-footer__claim {
    @include h-spacing(0);
    @include v-spacing(.5);
    margin-bottom: 0.5rem;

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @media #{$gtLine} {
        @include h-spacing(2em);
        @include island(2);

        flex-direction: row;
        align-items: flex-end;
    }
}

.l-footer__logo {
    height: 2em;
}
