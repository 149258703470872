@import "./src/common/general.scss";
.l-footer__subsection {
    @include v-spacing(.25);
    
    text-align: center;

    @media #{$gtLayout} {
        text-align: left;
    }
}
