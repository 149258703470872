@import "./src/common/general.scss";
.l-footer__section {
  border-top: 1px solid $colorGrey--200;

  padding: ($lineHeight * 0.5rem) 0;

  @media #{$gtLine} {
    padding: ($lineHeight * 1rem) 0;
  }

  &--tight {
    padding: ($lineHeight * 0.5rem) 0;
  }
}
