@import "./src/common/general.scss";
$socialMediaIcons: (
  facebook: $colorFacebook,
  twitter: $colorX,
  linkedin: $colorLinkedIn,
  youtube: $colorYoutube,
  instagram: $colorInstagram,
);

.l-footer__social-networks {
  display: flex;
  justify-content: center;
  align-items: center;
  @include v-spacing(0.25);

  flex-direction: column;

  @media #{$gtLine} {
    @include v-spacing(0);
    @include h-spacing(2em);
    flex-direction: row;
  }
}

.l-footer__icon {
  font-size: 2em;
  transition: opacity 0.3s;

  @each $key, $value in $socialMediaIcons {
    &--#{$key} {
      color: $value;
    }
  }

  &--instagram {
    .e-icon {
      background: $colorInstagram;
    }
  }

  &:hover,
  &:focus {
    opacity: 0.6;
  }

  @media #{$gtLine} {
    font-size: 1.875em;
  }
}
