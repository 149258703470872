@import "./src/common/general.scss";
.l-footer__column.l-footer__contact-section {
    @include v-spacing(1);
    order: 1;
    border-top: none;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: auto;
    margin-top: 0;
    padding: ($lineHeight * 0.75rem) 0;

    @media #{$gtLine} {
        flex: 1 0 100%;
        padding-top: 0;
        margin-top: ($lineHeight * 1rem);
    }

    @media #{$gtLayout} {
        flex: 0 1 auto;
        align-items: flex-start;
        margin-top: 0;
    }
}

.l-footer_mobile-app-list {
    flex-wrap: nowrap;
}
